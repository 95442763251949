<template>
    <v-dialog v-model="dialogModel" :retain-focus="false" content-class="mv-0 mh-0 pv-0 ph-0 d-flex flex-column justify-center align-center">
          <v-card :width="smAndUp ? '470px' : undefined">
            <v-card-title>
              <span class="text-h5">{{ isUpdate ? 'Edit Tracking Number' : 'Save Tracking Number' }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field 
                    :class="isDarkTheme ? 'text-input-white' : ''" 
                    variant="outlined" 
                    label="Tracking Number" 
                    v-model="trackerToSave" 
                    readonly></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field 
                    :class="isDarkTheme ? 'text-input-white' : ''" 
                    variant="outlined" 
                    label="Carrier" 
                    v-model="carrierPretty"
                    readonly></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                    ref="descriptionTextFieldRef"
                    :class="isDarkTheme ? 'text-input-white' : ''"
                    variant="outlined" 
                    label="Description" 
                    hint="Enter a description for this tracking number." 
                    persistent-hint 
                    v-model="descriptionToSave"
                    @keydown.enter="saveTrackerCommit"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn v-if="isUpdate" color="error" text @click="deleteTrackerCommit">
                Delete
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogModel = false">
                Cancel
              </v-btn>
              <v-btn text @click="saveTrackerCommit">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
</template>

<script>
import { inject, nextTick, ref, watch } from 'vue'
import { useDisplay, useTheme } from 'vuetify'
import { addUserTracker, updateUserTracker } from '../utils/authUtils.js'
import { useAuth0 } from '@auth0/auth0-vue'
import { prettyStringForEnum } from '../utils/trackingUtils.js'

export default {
    name: 'TrackerSaveDialog',
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        tracker: {
            type: String,
            required: true
        },
        carrier: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        isUpdate: {
            type: Boolean,
            required: false,
            default: false
        },
        deleteTrackerFunction: {
          type: Function,
          required: false,
          default: (tracker) => { console.log(`Tracker (${tracker}) delete action w/o delete function!`)}
        }
    },
    emits: ['update:modelValue', 'saved-tracker-updated'],
    setup(props, context) {
        // Logger
        const logger = inject('vuejs3-logger')

        const dialogModel = ref(false)

        const trackerToSave = ref('')
        const carrierToSave = ref('')
        const descriptionToSave = ref('')

        const carrierPretty = ref('')

        const descriptionTextFieldRef = ref(null)

        // Scrolling / Focus behaviors
        const focusDescriptionTextField = () => {
          nextTick(() => {
            descriptionTextFieldRef.value?.focus()
            logger.debug(`Focused save dialog (${descriptionTextFieldRef.value}) description field...`)
          })
        }

        //Theme Handling
        const theme = useTheme()
        const isDarkTheme = ref(theme.current.value === 'dark')
        watch(theme.current, () => {
          if (theme.current.value === 'dark') {
            isDarkTheme.value = true
          } else {
            isDarkTheme.value = false
          }
        })

        // Responsive Sizing Logic
        const { smAndUp } = useDisplay()

        // Prop Handling
        watch(() => props.tracker, (val) => {
          trackerToSave.value = val
          // Also reset description
          descriptionToSave.value = props.description
        }, { immediate: true })

        watch(() => props.carrier, (val) => {
          carrierToSave.value = val
        }, { immediate: true })

        watch(() => props.description, (val) => {
          descriptionToSave.value = val
        }, { immediate: true })

        watch(carrierToSave, (val) => {
            carrierPretty.value = prettyStringForEnum(val)
        })

        // V-Model 2-Way Binding
        watch(() => props.modelValue, (val) => {
          dialogModel.value = val
          // Also reset description
          descriptionToSave.value = props.description
        }, { immediate: true })

        watch(dialogModel, (val) => {
          if (val) {
            // Focus the description text field at dialog open time
            focusDescriptionTextField()
          }
          context.emit('update:modelValue', val) // Pass change up to parent component
        })

        // Save/Edit Functions
        const { getAccessTokenSilently, user } = useAuth0()
        const saveTrackerCommit = async () => {
            if (props.isUpdate) {
                // Perform update
                const updated = await updateUserTracker(await getAccessTokenSilently(), user.value?.sub, { 
                    carrier: carrierToSave.value,
                    tracker: trackerToSave.value,
                    description: descriptionToSave.value
                })
                logger.debug(`Updated tracker: ${JSON.stringify(updated)}`)
                // Signal data updated
                context.emit('saved-tracker-updated', updated)
            } else {
                // Commit the save
                const saved = await addUserTracker(await getAccessTokenSilently(), user.value?.sub, { 
                    carrier: carrierToSave.value,
                    tracker: trackerToSave.value,
                    description: descriptionToSave.value
                })
                logger.debug(`Saved tracker: ${JSON.stringify(saved)}`)
                // Signal data updated
                context.emit('saved-tracker-updated', saved)
            }
          
            // Close the dialog
            dialogModel.value = false
        }
        const deleteTrackerCommit = async () => {
          await props.deleteTrackerFunction?.({
            carrier: carrierToSave.value,
            tracker: trackerToSave.value,
            description: descriptionToSave.value
          })
          // Close the dialog
          dialogModel.value = false
        }

        return {
            dialogModel,
            descriptionTextFieldRef,
            isDarkTheme,
            // Responsive Sizing
            smAndUp,
            trackerToSave,
            carrierToSave,
            carrierPretty,
            descriptionToSave,
            saveTrackerCommit,
            deleteTrackerCommit
        }
    }
}
</script>

<style lang="scss" scoped>

.text-input-white {
  :deep(.v-field__input) {
    color: rgba(255, 255, 255, 0.6) !important;
  }
}
</style>